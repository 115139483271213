import React, {useState} from 'react'
import Axios from 'axios'
import './style.scss'
import * as styles from './contact.module.scss'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import {SiFacebook, SiInstagram} from 'react-icons/si';
import { OutboundLink } from "gatsby-plugin-google-gtag"
import ReCAPTCHA from "react-google-recaptcha"



const ContactPage = () => {

    const handleRecaptcha =  () => {}

    const [contactNotification, setContactNotification] = useState("");
	const [contactNotificationStyle, setContactNotificationStyle] = useState(`${styles.contactNotification}`)

    const [contactName, setContactName] = useState("");
	const [contactEmail, setContactEmail] = useState("");
    const [contactMessage, setContactMessage] = useState("");
    const [contactConsent, setContactConsent] = useState(false);
	const recaptchaRef = React.createRef()


    const onNameChange = event => {setContactName(event.target.value)}
    const onEmailChange = event => {setContactEmail(event.target.value)}
    const onMessageChange = event => {setContactMessage(event.target.value)}
    const onConsentChange = event => {setContactConsent(event.target.checked)}

    const handleSubmit = event => {
        event.preventDefault();
		const recaptchaValue = recaptchaRef.current.getValue()

        if(!contactEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
			setContactNotificationStyle(`${styles.contactNotification} ${styles.contactNotificationError}`)
			setContactNotification("Adres email jest nieprawidłowy.")
			return
		}
        if(!contactConsent){
			setContactNotificationStyle(`${styles.contactNotification} ${styles.contactNotificationError}`)
			setContactNotification("Potwierdź zgodę na przetwarzanie danych osobowych.")
			return
		}
        if(contactName == ""){
			setContactNotificationStyle(`${styles.contactNotification} ${styles.contactNotificationError}`)
			setContactNotification("Podaj swoje imię.")
			return
		}
        if(contactMessage == ""){
			setContactNotificationStyle(`${styles.contactNotification} ${styles.contactNotificationError}`)
			setContactNotification("Wypełnij pole wiadomości.")
			return
		}
		if(recaptchaValue == ""){
			setContactNotificationStyle(`${styles.contactNotification} ${styles.contactNotificationError}`)
			setContactNotification("Potwierdź, że nie jesteś robotem.")
			return
		}

        const data = { 
			"email": contactEmail,
            "name": contactName,
            "message": contactMessage,
        	"g-recaptcha-response": recaptchaValue
		};

		const headers ={
			'Content-Type': 'application/json;charset=UTF-8',
      		"Access-Control-Allow-Origin": "*",
		}

		Axios.post('https://us-central1-ambarassed.cloudfunctions.net/forwardContactForm ', {"data": data}, {
			headers: headers
		  })
		.then(res => {
			setContactNotificationStyle(`${styles.contactNotification} ${styles.contactNotificationSuccess}`)
			setContactNotification("Wiadomość została wysłana!")
		})
		.catch(error => {
			setContactNotificationStyle(`${styles.contactNotification} ${styles.contactNotificationError}`)
			setContactNotification("Błąd serwera.")
			console.log(error);
		});

        
    }


  return (
  <Layout pageTitle="Kontakt">
      <section className={styles.row}>
            <h3>Skontaktuj się ze mną</h3>
            <p>
                <OutboundLink href="https://www.facebook.com/ale.ambaras/" className={styles.socialIco} aria-label="Ambaras na Facebooku" ><SiFacebook size="2.2rem" aria-hidden="true"/></OutboundLink>
				<OutboundLink href="https://www.instagram.com/ale.ambaras/" className={styles.socialIco} aria-label="Ambaras na Instagramie" ><SiInstagram size="2.2rem" aria-hidden="true"/></OutboundLink>
            </p>

            <form className={styles.contactForm} onSubmit={handleSubmit} method="post">

                <div className={contactNotificationStyle}>
					{contactNotification}
				</div>

                <label htmlFor="contact-name">Twoje imię:</label>
                <input type="text" id="contact-name" name="firstname" placeholder="IMIĘ" onchange={onNameChange} required/>

                <label htmlFor="contact-email">Twój email:</label>
                <input type="email" id="contact-email" name="firstname" placeholder="EMAIL" onchange={onEmailChange} required/>

                <label htmlFor="WIADOMOŚĆ">Wiadomość:</label>
                <textarea id="WIADOMOŚĆ" name="subject" placeholder="TREŚĆ WIADOMOŚCI" onchange={onMessageChange} required></textarea>

                <label className={styles.checkbox} htmlFor="consent"> 
                    <input type="checkbox" id="consent" name="consent" onchange={onConsentChange} required></input>
                    Wyrażam zgodę na przetwarzanie moich danych osobowych przez Administratora niniejszej strony internetowej w celu kontaktu z Administratorem.
                </label>
                
                <div className={styles.captchaContainer}>
                    <ReCAPTCHA
                        sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                        ref={recaptchaRef}
                    />
                </div>
              
                <input className={`btn ${styles.contactButton}`} type="submit" value="WYŚLIJ" alt="Submit" aria-label="Wyśij wiadomość"/>
            </form>
      </section>
  </Layout>
  )
}

export default ContactPage;